import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const AnimatedLineGraph = ({ data }) => {
    const ref = useRef();

    useEffect(() => {
        if (data && data.length) {
            drawChart();
        }
    }, [data]); // Redraw chart when data changes

    const drawChart = () => {
        const svg = d3.select(ref.current).select("svg");
        svg.selectAll("*").remove(); // Clear svg content before redrawing

        const width = svg.node().getBoundingClientRect().width;
        const height = svg.node().getBoundingClientRect().height;
        const margin = { top: 20, right: 30, bottom: 30, left: 40 };
        const chartWidth = width - margin.left - margin.right;
        const chartHeight = height - margin.top - margin.bottom;

        const x = d3.scaleLinear()
            .domain(d3.extent(data, d => d.yearGroup))
            .range([0, chartWidth]);

        const y = d3.scaleLinear()
            .domain([0, d3.max(data, d => d.averageAward)])
            .range([chartHeight, 0]);

        const line = d3.line()
            .x(d => x(d.yearGroup))
            .y(d => y(d.averageAward));

        const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

        // Explicitly set tick values based on the yearGroup values in the data
        g.append("g")
            .attr("transform", `translate(0,${chartHeight})`)
            .call(d3.axisBottom(x)
                .tickValues(data.map(d => d.yearGroup))
                .tickFormat(d => isNaN(d) ? '' : d3.format('d')(d)));

        // Remove the numbers (tick labels) on the y axis
        g.append("g")
            .call(d3.axisLeft(y).tickFormat(''));

        // Change the line color to white
        const path = g.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", "black") // Line color set to white
            .attr("stroke-width", 1.5)
            .attr("d", line);

        if (path.node() && typeof path.node().getTotalLength === 'function') {
            const totalLength = path.node().getTotalLength();
            path
                .attr("stroke-dasharray", totalLength + " " + totalLength)
                .attr("stroke-dashoffset", totalLength)
                .transition()
                .duration(2000)
                .attr("stroke-dashoffset", 0);
        }
    };

    return (
        <div ref={ref} style={{ width: '100%', height: '100px' }}>
            <svg style={{ width: '100%', height: '100%' }}></svg>
        </div>
    );
};

export default AnimatedLineGraph;
