import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

// Add `title` as an optional prop
const AnimatedPieChart = ({ data, title, labels }) => {
    const ref = useRef();
    const labelsRef = useRef();

    useEffect(() => {
        if (ref.current && data) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const { width } = entry.contentRect;
                    drawChart(width); // Pass width to drawChart
                }
            });
            resizeObserver.observe(ref.current);

            return; // Cleanup
        }
    }, [data]); // Dependency array to avoid unnecessary redraws

    const drawChart = (chartWidth) => {
        const height = Math.min(1500, chartWidth); // Adjusted for a realistic size
        const outerRadius = height / 2 - 10;
        const innerRadius = outerRadius * 0.75;
        const colorScale = d3.scaleOrdinal(d3.schemeTableau10);

        // Clear previous content
        d3.select(ref.current).selectAll("*").remove();

        const svg = d3.select(ref.current)
            .attr('viewBox', [-chartWidth / 2, -height / 2, chartWidth, height])
            .attr('width', chartWidth)
            .attr('height', height);

        // Conditional rendering of title
        if (title) {
            svg.append('text')
                .attr('x', 0)
                .attr('y', 0)
                .attr('text-anchor', 'middle')
                .style('font-size', '20px')
                .style('font-weight', 'bold')
                .text(title);
        }

        const pie = d3.pie()
            .sort(null)
            .value(d => d.percentage);

        const arc = d3.arc()
            .innerRadius(innerRadius)
            .outerRadius(outerRadius);

        const path = svg.selectAll("path")
            .data(pie(data))
            .join("path")
            .attr("fill", (d, i) => colorScale(i))
            .attr("d", arc)
            .each(function (d) { this._current = d; }); // Store the initial angles

        // Arc tween to smoothly transition between different data states
        const arcTween = (a) => {
            const i = d3.interpolate(this._current, a);
            this._current = i(0);
            return (t) => arc(i(t));
        };

        // Draw labels below the chart with bullet points
        d3.select(labelsRef.current)
            .selectAll("div")
            .data(data)
            .join("div")
            .style("display", "flex")
            .style("align-items", "center")
            .html(d => `<span style="font-size: 1.5rem; vertical-align:centre; color: ${colorScale(data.indexOf(d))};">&#9679;</span> <span style="margin-left: 8px; vertical-align:centre; color: black;">${d.name}- ${d.percentage}% </span>`);
    };

    return (
        <div style={{ width: "100%" }}>
            <svg style={{ width: "100%" }} ref={ref}></svg>
            {labels &&
                <div ref={labelsRef}></div> 
            }
        </div>
    );
};

export default AnimatedPieChart;
