import React from "react";
import { useParams } from 'react-router-dom';
import Characteristics from "./characteristics.js"
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Portfolio from "../../portfolio.js";

function AreaPage(props) {


    const { areaPath } = useParams();
    const area = props.areaData.filter(area => area.path === areaPath)[0]
    console.log("area", area)
    const areas = props.areaData
    const schoolData = props.schoolData
    const user = props.user
    const sidebarIcons = []

    for (let i = 0; areas.length > i; i++) {
        sidebarIcons.push(<a className="row" href={"/student/area/" + areas[i].path}><div><img src={areas[i].imageUrl} className="area-page-image col-6" /><p className="col-6">area {i + 1}</p></div></a>)
    }

    if (area) {
        return (

            <Routes>
                <Route path='/' element={
                    <div className="container">

                        <div className="row">
                            <div className="sidebar col-12 col-lg-2">
                                <img src={area.imageUrl} className={"area-page-image"} />
                            </div>

                            <div className="col-12 col-lg-10">

                                <div class="jumbotron jumbotron-fluid">
                                    <div class="container">
                                        <p class="lead"> {area.name}</p>
                                        <h1 class="display-4">{area.motto} </h1>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="sidebar col-12 col-lg-2">
                                <Link to={"/student/" + schoolData.path}> Back </Link>
                                <div style={{ textAlign: "right" }}>
                                    <a target="_blank" href={`/student/${schoolData.path}/area/${area.path}/portfolio`} className="btn btn-outline-secondary">Download Portfolio</a>
                                </div>
                            </div>

                            <div className="col-12 col-lg-10">

                                <Characteristics schoolData={schoolData} user={user} area={area} />
                            </div>
                        </div>
                    </div>

                } />
                <Route path='/portfolio' element={<Portfolio schoolData={schoolData} user={user} area={area} />} />

            </Routes>

        )
    } else {
        return (<div>can't find area</div>)
    }

}

export default AreaPage;