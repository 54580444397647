import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faChartBar, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import SocialMediaPost from "./socialMediaPost";
import AnimatedPieChart from './animatedPieChart';
import AnimatedLineGraph from './animatedLineGraph';
import { getFunctions, httpsCallable } from "firebase/functions";



const TeacherStudentDashboard = ({ values, schoolData, areasData, socialMediaPosts, changeParentTab }) => {


    const calculateStatistics = (values, schoolData, areasData, socialMediaPosts) => {

        let totalAwards = 0;
        let worldClassAwardsCount = 0;
        let subjectCounts = {};
        let achievementsAffectingOthersCount = 0;
        let totalAchievements = 0;
        let yearGroupAwards = {}; // Tracks the total awards per year group
        let yearGroupCounts = {}; // Tracks the count of awards per year group
        let createSocialMediaPosts = false;
        let socialMediaPostsArray = []

        // Assuming schoolData is available in the current scope
        if (false){//!(socialMediaPosts && Object.keys(socialMediaPosts).length > 0)) {
            createSocialMediaPosts = true;
        }

        let topAwardInfo = [];

        if (createSocialMediaPosts) {

            async function callCreateSocialMediaPost(schoolData, description, characteristic, uid, key, area) {
                const functions = getFunctions(); // Assuming getFunctions() is already defined/imported
                const createSocialMediaPost = httpsCallable(functions, 'createSocialMediaPost');
                try {
                    console.log("creating posts with this info", { schoolData, description, characteristic, uid, key, area },)
                    const result = await createSocialMediaPost({ schoolData, description, characteristic, uid, key, area });
                    console.log("Function call result:", result.data); // Accessing the data property for the result
                    return result.data; // Assuming the cloud function returns data in a data property
                } catch (error) {
                    console.error("Error calling createSocialMediaPost:", error);
                    throw error; // Rethrow to handle it in the calling context
                }
            }


            async function createPostsForTopAwards() {
                if (createSocialMediaPosts) { // Assuming this is correctly set based on your logic
                    for (const awardInfo of topAwardInfo) { // Assuming topAwardInfo is an array of award information
                        try {
                            const returnValue = await callCreateSocialMediaPost(schoolData, awardInfo.description, awardInfo.characteristic, awardInfo.uid, awardInfo.key, awardInfo.area);
                        } catch (error) {
                        }
                    }
                }
            }


            // Gather all awards with their corresponding value, uid, area, and key
            let allAwards = [];
            values.forEach(value => {
                if (value.grace) {
                    Object.keys(value.grace).forEach(key => {
                        const award = value.grace[key].award;
                        if (award !== undefined) { // Ensure the award exists
                            const description = value[`description${key}`]; // Concatenation to find description
                            const areaData = areasData.find(area => area.path === value.area); // Find matching area data
                            console.log("areaData dashboard", areaData)
                            const characteristic = areaData?.characteristics.find(char => char.id == key)?.name; // Find matching characteristic by key

                            allAwards.push({
                                uid: value.uid,
                                area: value.area,
                                key: key,
                                award: award,
                                description: description,
                                characteristic: characteristic
                            });
                        }
                    });
                }
            });

            // Sort and slice to get top awards
            allAwards.sort((a, b) => b.award - a.award);
            topAwardInfo = allAwards.slice(0, 10);

            createPostsForTopAwards();

        } else {

            socialMediaPostsArray = Object.values(socialMediaPosts).sort(() => 0.5 - Math.random());


        }

        // Now, each object in 'topAwardInfo' includes uid, area, key, award, description, and characteristic


        values.forEach(value => {
            // Process grace awards
            if (value.grace) {
                Object.keys(value.grace).forEach(key => {
                    const award = value.grace[key].award;
                    if (award !== undefined) {
                        totalAwards++;
                        if (award >= 80) {
                            worldClassAwardsCount++;
                        }

                        // Accumulate awards for the year group
                        const yearGroup = value.yearGroup;
                        if (yearGroupAwards[yearGroup]) {
                            yearGroupAwards[yearGroup] += award;
                            yearGroupCounts[yearGroup]++;
                        } else {
                            yearGroupAwards[yearGroup] = award;
                            yearGroupCounts[yearGroup] = 1;
                        }
                    }
                });
            }

            // Process embeddings for subjects and achievements
            if (value.embeddings) {
                Object.keys(value.embeddings).forEach(key => {
                    // Process subjects
                    const subjectArray = value.embeddings[key].embeddingData.subject;
                    if (subjectArray && subjectArray.length > 0) {
                        const subject = subjectArray[0];
                        if (subjectCounts[subject]) {
                            subjectCounts[subject]++;
                        } else {
                            subjectCounts[subject] = 1;
                        }
                    }

                    // Process achievements affecting others
                    const achievementArray = value.embeddings[key].embeddingData.achievement;
                    if (achievementArray && achievementArray.length > 0) {
                        const achievement = achievementArray[0];
                        totalAchievements++;
                        if (achievement.toLowerCase().includes("others")) {
                            achievementsAffectingOthersCount++;
                        }
                    }
                });
            }
        });

        const worldClassPercentage = (worldClassAwardsCount / totalAwards) * 100;

        // Convert subject counts to percentages
        let totalSubjects = Object.values(subjectCounts).reduce((acc, curr) => acc + curr, 0);
        let subjectPercentages = Object.keys(subjectCounts).reduce((acc, key) => {
            acc[key] = ((subjectCounts[key] / totalSubjects) * 100).toFixed(1);
            return acc;
        }, {});

        // Calculate the percentage of achievements affecting others
        const achievementsAffectingOthersPercentage = totalAchievements > 0
            ? ((achievementsAffectingOthersCount / totalAchievements) * 100).toFixed(1)
            : 0;

        // Calculate average award per year group
        let averageAwardsPerYearGroup = Object.keys(yearGroupAwards).map(yearGroup => ({
            yearGroup: parseInt(yearGroup), // Ensure year group is treated as a number
            averageAward: parseFloat((yearGroupAwards[yearGroup] / yearGroupCounts[yearGroup]).toFixed(2))
        }));

        // Sort by year group for the line graph
        averageAwardsPerYearGroup.sort((a, b) => a.yearGroup - b.yearGroup);

        return {
            worldClassPercentage: isNaN(worldClassPercentage) ? 0 : worldClassPercentage.toFixed(2),
            subjectPercentages,
            achievementsAffectingOthersPercentage, // Percentage of achievements affecting others
            averageAwardsPerYearGroup,
            socialMediaPostsArray // Reintegrated line graph data
        };
    };



    const { worldClassPercentage, subjectPercentages, averageAwardsPerYearGroup, achievementsAffectingOthersPercentage, socialMediaPostsArray } = calculateStatistics(values, schoolData, areasData, socialMediaPosts);

    const subjectDataForChart = Object.keys(subjectPercentages).map(key => ({
        name: key.replace("Learning", "").replace("Literature", "Lit"),
        percentage: parseFloat(subjectPercentages[key]) // Ensure it's a number
    }));

    const achievementsDataForChart = [
        { name: "Others", percentage: parseFloat(achievementsAffectingOthersPercentage) },
        { name: "Self", percentage: parseFloat((100 - achievementsAffectingOthersPercentage).toFixed(2)) },
    ];

    return (
        <div className="container-fluid">
            <div className="row">
                <div style={{ height: "100%" }} className="col-md-4 mb-3">
                    <div style={{ height: "100%" }} className="card card-shadow card-dashboard">

                        <div className="card-body">
                            <div className='row' >
                                <div className='col-7' >
                                    <h3 className="card-title">Data Insights</h3>
                                </div>
                                <div style={{ textAlign: "right" }} className='col-5' >
                                    <button onClick={() => changeParentTab('insight')} className="btn btn-primary">View More</button>
                                </div>
                            </div>
                            <hr />
                            <div style={{ paddingTop: "1rem", textAlign: "center" }} className='row'>
                                <div className='col-6 chart-container border-primary card'>


                                    <AnimatedPieChart  title={"Subjects"} data={subjectDataForChart} />


                                </div>
                                <div className='col-6 '>
                                    <AnimatedLineGraph data={averageAwardsPerYearGroup} />
                                    <h6>Quality of writing by year group</h6>

                                </div>
                            </div>
                            <div style={{ textAlign: "center", paddingTop: "2rem", textAlign: "left" }} className='row'>
                                <div className='col-6 chart-container text-primary border-primary card'>

                                    <AnimatedPieChart title={`${achievementsDataForChart[0].percentage}%`} data={achievementsDataForChart} />
                                    <h6 style={{ textAlign: "center" }}>Benefit others more than themselves</h6>
                                </div>
                                <div style={{ verticalAlign: "center", textAlign: "center" }} className='col-6'>

                                    <h2 className="text-primary" >{worldClassPercentage}%</h2>

                                    <h6>Responses are World Class</h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div style={{ paddingLeft: "4rem" }} className="col-md-8 mb-8">
                    <div className="card card-shadow card-dashboard">

                        <div className='card-body'>


                            <h3 className="card-title">Review Individuals
                            </h3>
                            <hr/>
                            <div className="row">



                                <button onClick={() => changeParentTab('allSubmissions')} className="btn btn-primary col-4">All Submissions</button>
                                <div className='col-4'> </div>
                                <button onClick={() => changeParentTab('allStudents')} className="btn btn-secondary col-4">All Students</button>


                            </div>






                        </div>
                    </div>



                    <div style={{ paddingTop: "4rem" }} >
                    </div>



                    <div className="card card-shadow card-dashboard">
                        <div className='card-body'>
                            <h3 className="card-title">Demonstrate Impact</h3>
                            <hr />
                            <div className='row'>
                                <div className='col-6'>
                                    <button onClick={() => changeParentTab('socialMedia')} className='btn btn-link p-0 text-left'> <FontAwesomeIcon icon="fa-share-nodes" />   Generate social media posts</button>

                                    {socialMediaPostsArray.length > 0 &&
                                        <SocialMediaPost postData={socialMediaPostsArray[0]} />
                                    }

                                </div>
                                <div className='col-6'>

                                    <ul style={{ textAlign: "left" }} className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            <div>

                                                <button onClick={() => changeParentTab('widgets')} className="btn btn-link p-0 text-left">
                                                    <FontAwesomeIcon icon="th-large" className="mr-3" />    Website Widgets
                                                </button>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <button className="btn btn-link p-0 text-left">
                                                <FontAwesomeIcon icon="envelope" className="mr-3" />    Newsletter
                                            </button>
                                        </li>
                                        <li className="list-group-item">
                                            <button className="btn btn-link p-0 text-left">
                                                <FontAwesomeIcon icon="file-alt" className="mr-3" />     Fact Sheets
                                            </button>
                                        </li>
                                    </ul>


                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default TeacherStudentDashboard;
