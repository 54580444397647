import React from 'react';
import SocialMediaPost from './socialMediaPost'; // Adjust the import path as necessary

const SocialMediaPosts = ({ socialMediaPostsArray }) => {
  return (
    <div className="">
      <div className="row">
        {socialMediaPostsArray.slice(0, 20).map((postData, index) => (
          <div key={index} className="col-md-3 mb-4">
            <div style={{padding:"2rem"}} className="p-3 ">
              <SocialMediaPost postData={postData} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialMediaPosts;
