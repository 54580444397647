import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { getFunctions, httpsCallable } from "firebase/functions";
import app from "../firebase"; // Ensure your Firebase app is initialized here

class ChangeStudentPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitAttempt: false,
            user: null,
            uid: null,
            message: "", // Add a message state to provide feedback to the user
        };
    }

    componentDidMount() {
        const users = this.props.users;
        const selectedKey = this.props.selected;
        if (selectedKey) {
            const user = users.find((value) => value.key === selectedKey);
            this.setState({ uid: user?.uid, user: user });
        }
    }

    handleSubmit = async (values) => {
        const { password } = values;
        const { uid } = this.state;

        if (!uid) {
            this.setState({ message: "No student selected." });
            return;
        }

        const functions = getFunctions(app);
        const changeStudentPassword = httpsCallable(functions, "changeStudentPassword");

        try {
            const response = await changeStudentPassword({
                studentUid: uid,
                newPassword: password,
                schoolId: this.props.schoolData.path, // Assuming `schoolId` is passed as a prop
            });

            if (response.data.error) {
                this.setState({ message: response.data.error });
            } else {
                this.setState({ message: "Password changed successfully." });
            }
        } catch (error) {
            console.error("Error changing password:", error);
            this.setState({ message: "Failed to change password." });
        }
    };

    validate = (values) => {
        const errors = {};
        if (!values.password) {
            errors.password = "Password is required";
        } else if (values.password.length < 6) {
            errors.password = "Password must be at least 6 characters";
        }

        if (!values.password2) {
            errors.password2 = "Confirm your password";
        } else if (values.password !== values.password2) {
            errors.password2 = "Passwords must match";
        }

        return errors;
    };

    render() {
        return (
            <div>
                <div className="my-modal">
                    <div className="my-modal-content">
                        <div className="my-modal-header">
                            <span className="close" onClick={() => this.props.closeAction()}>&times;</span>
                            <h2>Change Student Password</h2>
                        </div>
                        <Formik
                            initialValues={{ password: "", password2: "" }}
                            validate={this.validate}
                            onSubmit={this.handleSubmit}
                        > 
                            <Form>
                                <div className="my-modal-body form-group">

                                    <h4> {this.state.user?.firstName} {this.state.user?.lastName} </h4>
                                    <label htmlFor="password">New password for student</label>
                                    <Field type="password" id="password" name="password" className="form-control" />
                                    <ErrorMessage name="password" component="div" className="text-danger" />

                                    <label htmlFor="password2">Retype password</label>
                                    <Field type="password" id="password2" name="password2" className="form-control" />
                                    <ErrorMessage name="password2" component="div" className="text-danger" />

                                    <button type="submit" className="btn btn-primary">
                                        Submit
                                    </button>
                                    {this.state.message && <div className="mt-3">{this.state.message}</div>}
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangeStudentPassword;
