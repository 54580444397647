import React from "react";
import areasConfig from "../areasConfig";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import app from "../firebase";
import { getDatabase, set, ref } from "firebase/database";

class EditSelectedStudent extends React.Component {
    constructor(props) {
        super(props);
    }
    state = { editing: false, editingUid: null }

    updateUser = (values) => {
        const database = getDatabase(app)
        const inSchoolPath = ref(database, 'schools/' + this.props.schoolData.path + '/students/' + this.state.editingUid);


        console.log("pupil premium", values.pupilPremium)


        set(inSchoolPath, {
            details: {
                firstName: values.firstName || "",
                lastName: values.lastName || "",
                yearGroup: values.yearGroup || 0,
                class: values.class || "",
                gender: values.gender || "",
                sen: values.sen || false,
                pupilPremium: values.pupilPremium || false,

            },
        });

        this.setState({ editingUid: null })


    }

    render() {
        let user = {}
        let areaIcons = []
        const values = this.props.values
        const users = this.props.users
        const selectedKey = this.props.selected
        const submission = values.filter(value => value.key === selectedKey)[0]
        if (submission) {
            user = users.filter(user => user.uid === submission.uid)[0]
        } else {
            user = users.filter(user => user.uid === selectedKey)[0]
        }
        if (user) {
            let areas = areasConfig


            for (let i = 0; i < areas.length; i++) {
                const progressEstimate = Math.round(user[areas[i].path + "ProgressEstimate"] ? user[areas[i].path + "ProgressEstimate"] : 0)
                areaIcons.push(
                    <div className="col-lg-2 col-xl-2 col-sm-4 col-xs-4">
                        <p style={{ minHeight: "3rem" }} > <b>{areas[i].name}</b></p>


                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{ width: progressEstimate + "%" }} aria-valuenow={progressEstimate} aria-valuemin="0" aria-valuemax="100">{progressEstimate}%</div>
                        </div>
                        <p> {user[areas[i].path + "Mark"]?user[areas[i].path + "Mark"]:"No feedback requested" }</p>
                        <hr />

                    </div>
                )
            }

            return (
                <div className="card" style={{ marginTop: "1rem" }}>

                    <div style={{ padding: "2rem" }}>
                        {this.state.editingUid === user.uid &&

                            <Formik
                                initialValues={user}
                                onSubmit={async values => {
                                    await new Promise(resolve => setTimeout(resolve, 500));
                                    if (Object.keys(this.state.errors).length === 0) {

                                        console.log("submit")
                                        this.updateUser(values);
                                        // this.setState({ submitAttempt: true, values: values }, () => {

                                        // });

                                    }
                                    else {
                                        console.log("invalid fields, this is my state", this.state.errors)
                                        this.setState({ submitAttempt: true })
                                    }
                                }}
                                validate={values => {
                                    const errors = {}
                                    this.setState({ errors: errors })
                                }
                                }
                            >
                                <Form >

                                    <div className="row">
                                        <h4 className="col-9">Edit Details</h4>
                                        <div style={{ textAlign: "right" }} className="col-3">
                                            <button className="btn btn-secondary" onClick={() => { this.setState({ editingUid: null }) }}> Cancel</button>
                                            <button className="btn btn-primary" type="submit" > Save</button>
                                        </div>
                                    </div>

                                    <div className="row">

                                        <div className="col-4">
                                            <label >First name</label>
                                            <Field name="firstName" type="text" className={`${/*this.state.errors.firstName*/false ? "is-invalid" : ""} form-control`} />
                                            <div class="invalid-feedback">{"this.state.errors.firstName"}</div>
                                        </div>
                                        <div className="col-4">
                                            <label >Last name</label>
                                            <Field name="lastName" type="text" className={`${/*this.state.errors.lastName*/false ? "is-invalid" : ""} form-control`} />
                                            <div class="invalid-feedback">{"this.state.errors.lastName"}</div>
                                        </div>
                                        <div className="col-4">
                                            <label >Year Group</label>
                                            <Field name="yearGroup" type="number" className={`${/*this.state.errors.yearGroup*/false ? "is-invalid" : ""} form-control`} />
                                            <div class="invalid-feedback">{"this.state.errors.yearGroup"}</div>
                                        </div>
                                        <div className="col-4">
                                            <label >Class/Tutor Group</label>
                                            <Field name="class" type="text" className={`${/*this.state.errors.class*/false ? "is-invalid" : ""} form-control`} />
                                            <div class="invalid-feedback">{"this.state.errors.class"}</div>
                                        </div>
                                        <div className="col-4">
                                            <label >Gender</label>
                                            <Field as="select" name="gender" className={`${/*this.state.errors.class*/false ? "is-invalid" : ""} form-control`} placeholder=" " >
                                                <option></option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                            </Field>
                                            <div class="invalid-feedback">{"this.state.errors.gender"}</div>
                                        </div>
                                    </div>

                                    <hr />
                                    <div className="row">
                                        <h4>Inclusion tracking</h4>
                                        <p>This is only seen by school staff</p>
                                        <div className="col-2">
                                            <label>Pupil premium</label>
                                            <Field as="select" name="pupilPremium" className={`${/*this.state.errors.class*/false ? "is-invalid" : ""} form-control`} placeholder=" " >
                                                <option value={false}>No</option>
                                                <option value={true}>Yes</option>
                                            </Field>
                                        </div>

                                        <div className="col-2">
                                            <label>SEND</label>
                                            <Field as="select" name="send" className={`${/*this.state.errors.class*/false ? "is-invalid" : ""} form-control`} placeholder=" " >
                                                <option value={false}>No</option>
                                                <option value={true}>Yes</option>
                                            </Field>
                                        </div>

                                        <div className="col-2">
                                            <label>More able</label>
                                            <Field as="select" name="moreAble" className={`${/*this.state.errors.class*/false ? "is-invalid" : ""} form-control`} placeholder=" " >
                                                <option>No</option>
                                                <option value={true}>Yes</option>
                                            </Field>
                                        </div>

                                        <div className="col-2">
                                            <label>Low attaining</label>
                                            <Field as="select" name="highAchiever" className={`${/*this.state.errors.class*/false ? "is-invalid" : ""} form-control`} placeholder=" " >
                                                <option>No</option>
                                                <option value={true}>Yes</option>
                                            </Field>
                                        </div>

                                        {/* <div className="col-2">
                                            <label>Ethnicity</label>
                                            <Field as="select" name="ethnicity" className={`${/*this.state.errors.classfalse ? "is-invalid" : ""} form-control`} placeholder=" " >
                                                <option></option>
                                                <option value="White British">White British</option>
                                                <option value="White Irish">White Irish</option>
                                                <option value="White Other">White Other</option>
                                                <option value="Black African">Black African</option>
                                                <option value="Black Caribbean">Black Caribbean</option>
                                                <option value="Indian">Indian</option>
                                                <option value="Pakistani">Pakistani</option>
                                                <option value="Bangladeshi">Bangladeshi</option>
                                                <option value="Chinese">Chinese</option>
                                                <option value="Other Asian">Other Asian</option>
                                                <option value="Arab">Arab</option>
                                                <option value="Other Ethnic Group">Other Ethnic Group</option>
                                            </Field>
                                        </div> */}

                                    </div>

                                </Form>
                            </Formik>

                        }

                        {this.state.editingUid !== user.uid &&
                            < div >


                                <div style={{ marginBottom: "1rem" }} className="row">
                                    <div className="col-8">
                                        <h3 >{user.firstName} {user.lastName}, {user.yearGroup ? "Year " + user.yearGroup : ""}</h3>
                                    </div>
                                    <div style={{ textAlign: "right" }} className="col-4">
                                        <button className="btn btn-primary" onClick={() => { this.setState({ editing: true, editingUid: user.uid }) }}> Edit Details</button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-8">
                                        <h5>Details</h5>
                                        <p>Gender: {user.gender}</p>
                                        <p>Class/TutorGroup: {user.class}</p>
                                    </div>
                                    <div className="col-4">
                                        <h5>Inclusion tracking</h5>
                                        <div className="row">
                                            <div className="col-6 inclusion-field">
                                                <span>Pupil Premium</span><br />
                                                {user.pupilPremium === "true" ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faXmark} />}
                                            </div>
                                            <div className="col-6 inclusion-field">
                                                <span>SEND</span><br />
                                                {user.send === "true" ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faXmark} />}
                                            </div>
                                            <div className="col-6 inclusion-field">
                                                <span>More able</span><br />
                                                <FontAwesomeIcon icon={faXmark} />
                                            </div>
                                            <div className="col-6 inclusion-field">
                                                <span>Low attaining</span><br />
                                                <FontAwesomeIcon icon={faXmark} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <hr />
                                    <h5>Progress</h5>
                                    {areaIcons}
                                </div>
                            </div>
                        }
                    </div>
                </div >);
        } else {
            return (<></>)
        }

    }
}

export default EditSelectedStudent;