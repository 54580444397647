import React from "react";
import { Link } from 'react-router-dom';
import { getDatabase, ref, onValue } from "firebase/database";
import app from '../../../firebase.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';

class AreaIcon extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      state: "Not Started",
      mark: null,
      characteristicsStatus: {},
      customInformation: null
    };

  }

  componentDidMount() {
    const database = getDatabase(app);
    const { schoolData, area, user } = this.props;

    console.log("user in icon", user)
    console.log("path for database", `schools/${schoolData.path}/submissions/${area.path}/${user.uid}`)
    const areaStatePath = ref(database, `schools/${schoolData.path}/submissions/${area.path}/${user.uid}/state`);
    const areaMarkPath = ref(database, `schools/${schoolData.path}/submissions/${area.path}/${user.uid}/mark`);

    onValue(areaStatePath, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        this.setState({ state: data });
      }
    });

    onValue(areaMarkPath, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        this.setState({ mark: data });
      }
    });

    const customInformation = [{
      // participation character matched
      areaPath: "teoUOqn",
      characteristics: [{
        id: 77846790426,
        text: "Bronze",
        color: '#cd7f32',
        textShadow: '0 1px 0 #8c6239, 0 2px 0 #7a5733, 0 3px 0 #6b4d2e, 0 4px 0 #5d4329, 0 5px 0 #503a24, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      },
      {
        id: 18716484173,
        text: "Silver",
        color: '#c0c0c0',
        textShadow: '0 1px 0 #888, 0 2px 0 #777, 0 3px 0 #666, 0 4px 0 #555, 0 5px 0 #444, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      },
      {
        id: 19888150959,
        text: "Gold",
        color: '#d4af37',
        textShadow: '0 1px 0 #b8940a, 0 2px 0 #a18309, 0 3px 0 #8b7208, 0 4px 0 #775e07, 0 5px 0 #644c06, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      }]
    },{
         // leadership  
      areaPath: "28vM7fA",
      characteristics: [{
        id: 77846790426,
        text: "Bronze",
        color: '#cd7f32',
        textShadow: '0 1px 0 #8c6239, 0 2px 0 #7a5733, 0 3px 0 #6b4d2e, 0 4px 0 #5d4329, 0 5px 0 #503a24, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      },
      {
        id: 18716484173,
        text: "Silver",
        color: '#c0c0c0',
        textShadow: '0 1px 0 #888, 0 2px 0 #777, 0 3px 0 #666, 0 4px 0 #555, 0 5px 0 #444, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      },
      {
        id: 19888150959,
        text: "Gold",
        color: '#d4af37',
        textShadow: '0 1px 0 #b8940a, 0 2px 0 #a18309, 0 3px 0 #8b7208, 0 4px 0 #775e07, 0 5px 0 #644c06, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      }]
    },{
      //exellence
      areaPath: "ZAf4AYZ",
      characteristics: [{
        id: 77846790426,
        text: "Bronze",
        color: '#cd7f32',
        textShadow: '0 1px 0 #8c6239, 0 2px 0 #7a5733, 0 3px 0 #6b4d2e, 0 4px 0 #5d4329, 0 5px 0 #503a24, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      },
      {
        id: 18716484173,
        text: "Silver",
        color: '#c0c0c0',
        textShadow: '0 1px 0 #888, 0 2px 0 #777, 0 3px 0 #666, 0 4px 0 #555, 0 5px 0 #444, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      },
      {
        id: 19888150959,
        text: "Gold",
        color: '#d4af37',
        textShadow: '0 1px 0 #b8940a, 0 2px 0 #a18309, 0 3px 0 #8b7208, 0 4px 0 #775e07, 0 5px 0 #644c06, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      }]
    },{
      //diversity
      areaPath: "QHs29Zk",
      characteristics: [{
        id: 77846790426,
        text: "Bronze",
        color: '#cd7f32',
        textShadow: '0 1px 0 #8c6239, 0 2px 0 #7a5733, 0 3px 0 #6b4d2e, 0 4px 0 #5d4329, 0 5px 0 #503a24, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      },
      {
        id: 18716484173,
        text: "Silver",
        color: '#c0c0c0',
        textShadow: '0 1px 0 #888, 0 2px 0 #777, 0 3px 0 #666, 0 4px 0 #555, 0 5px 0 #444, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      },
      {
        id: 19888150959,
        text: "Gold",
        color: '#d4af37',
        textShadow: '0 1px 0 #b8940a, 0 2px 0 #a18309, 0 3px 0 #8b7208, 0 4px 0 #775e07, 0 5px 0 #644c06, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      }]
    },{
      //giving
      areaPath: "facQfB0",
      characteristics: [{
        id: 77846790426,
        text: "Bronze",
        color: '#cd7f32',
        textShadow: '0 1px 0 #8c6239, 0 2px 0 #7a5733, 0 3px 0 #6b4d2e, 0 4px 0 #5d4329, 0 5px 0 #503a24, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      },
      {
        id: 18716484173,
        text: "Silver",
        color: '#c0c0c0',
        textShadow: '0 1px 0 #888, 0 2px 0 #777, 0 3px 0 #666, 0 4px 0 #555, 0 5px 0 #444, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      },
      {
        id: 19888150959,
        text: "Gold",
        color: '#d4af37',
        textShadow: '0 1px 0 #b8940a, 0 2px 0 #a18309, 0 3px 0 #8b7208, 0 4px 0 #775e07, 0 5px 0 #644c06, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      }]
    },{
      //environment
      areaPath: "p0sIckL",
      characteristics: [{
        id: 77846790426,
        text: "Bronze",
        color: '#cd7f32',
        textShadow: '0 1px 0 #8c6239, 0 2px 0 #7a5733, 0 3px 0 #6b4d2e, 0 4px 0 #5d4329, 0 5px 0 #503a24, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      },
      {
        id: 18716484173,
        text: "Silver",
        color: '#c0c0c0',
        textShadow: '0 1px 0 #888, 0 2px 0 #777, 0 3px 0 #666, 0 4px 0 #555, 0 5px 0 #444, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      },
      {
        id: 19888150959,
        text: "Gold",
        color: '#d4af37',
        textShadow: '0 1px 0 #b8940a, 0 2px 0 #a18309, 0 3px 0 #8b7208, 0 4px 0 #775e07, 0 5px 0 #644c06, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      }]
    },{
      //service
      areaPath: "nExdhrG",  
      characteristics: [{
        id: 92863253643,
        text: "Bronze",
        color: '#cd7f32',
        textShadow: '0 1px 0 #8c6239, 0 2px 0 #7a5733, 0 3px 0 #6b4d2e, 0 4px 0 #5d4329, 0 5px 0 #503a24, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      },
      {
        id: 13730909503,
        text: "Silver",
        color: '#c0c0c0',
        textShadow: '0 1px 0 #888, 0 2px 0 #777, 0 3px 0 #666, 0 4px 0 #555, 0 5px 0 #444, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      },
      {
        id: 44956160865,
        text: "Gold",
        color: '#d4af37',
        textShadow: '0 1px 0 #b8940a, 0 2px 0 #a18309, 0 3px 0 #8b7208, 0 4px 0 #775e07, 0 5px 0 #644c06, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
      }]
    }
  ];

    this.setState({ customInformation });

    const matchedCustomInfo = customInformation.find(info => info.areaPath === area.path);
    if (matchedCustomInfo) {
      matchedCustomInfo.characteristics.forEach(characteristic => {
        const gracePath = ref(database, `schools/${schoolData.path}/submissions/${area.path}/${user.uid}/grace/${characteristic.id}/award`);
        onValue(gracePath, (snapshot) => {
          const data = snapshot.val();
          console.log("data to icon", data)
          this.setState(prevState => ({
            characteristicsStatus: {
              ...prevState.characteristicsStatus,
              [characteristic.id]: data && typeof data === 'number' && data >= 70
            }
          }));
        });
      });
    }
  }

  imageLoaded = () => {
    const delay = Math.random() * 500;
    setTimeout(() => {
      this.setState({ loaded: true });
    }, delay);
  }

  render() {
    const { loaded, characteristicsStatus, customInformation } = this.state;
    const { area } = this.props;

    const matchedCustomInfo = customInformation ? customInformation.find(info => info.areaPath === area.path) : null;

    return (
      <div style={{ textAlign: "center" }} className={loaded ? "slide-in-elliptic-top-fwd" : "hidden"}>
        <Link to={"area/" + this.props.area.path}>
          <img className="area-home-icon" src={this.props.area.imageUrl} onLoad={this.imageLoaded} />
        </Link>
        {matchedCustomInfo && (
          <div className="mt-3" style={{ fontSize: "1.5rem" }}>
            <ul className="list-unstyled" style={{ verticalAlign: "middle" }}>
              {matchedCustomInfo.characteristics.map(characteristic => (
                <li key={characteristic.id} style={{ color: characteristic.color, textShadow: characteristic.textShadow }}>
                  <div className="row">
                    <div style={{textAlign:"left"}} className="col-9">
                      {characteristic.text}
                    </div>
                    <div className="col-3">
                      <FontAwesomeIcon icon={characteristicsStatus[characteristic.id] ? faCheckSquare : faSquare} className={characteristicsStatus[characteristic.id] ? "text-success" : "text-secondary"} />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default AreaIcon;
