import React, { useState, useEffect } from 'react';
import ReportLayout from './reportLayout';
import RunReportGenerator from './runReportGenerator';
import { getDatabase, ref, get, onValue } from "firebase/database";
import app from '../../firebase';

const ReportGeneratorHome = ({ schoolData, extendedSubmissions }) => {
    const [reportTemplates, setReportTemplates] = useState([]);
    const [selectedReportId, setSelectedReportId] = useState(null);
    const [initialReportData, setInitialReportData] = useState(null);
    const [view, setView] = useState(''); // 'edit' or 'run'
    const [previousReports, setPreviousReports] = useState([]);
    const [reportRunData, setReportRunData] = useState(null);

    useEffect(() => {
        const db = getDatabase(app);
        const templatesRef = ref(db, 'schools/' + schoolData.path + '/private/reportTemplates');

        // Listen for changes in the report templates
        onValue(templatesRef, (snapshot) => {
            if (snapshot.exists()) {
                const templates = snapshot.val();
                const templateArray = Object.keys(templates).map(id => ({
                    id,
                    name: templates[id].name || 'Unnamed Template'
                }));
                setReportTemplates(templateArray);
            } else {
                setReportTemplates([]);
            }
        });

    }, [schoolData.path]);

    const loadReportTemplate = async (reportId) => {
        const db = getDatabase(app);
        const templateRef = ref(db, 'schools/' + schoolData.path + '/private/reportTemplates/' + reportId);
        const snapshot = await get(templateRef);

        if (snapshot.exists()) {
            setSelectedReportId(reportId);
            setInitialReportData(snapshot.val());
            loadPreviousReports(reportId);
        }
    };

    const loadPreviousReports = async (reportId) => {
        const db = getDatabase(app);
        const reportsRef = ref(db, `schools/${schoolData.path}/private/reportRuns/${reportId}`);
        const snapshot = await get(reportsRef);

        if (snapshot.exists()) {
            const reports = snapshot.val();
            const reportsArray = Object.keys(reports).map(id => ({
                id,
                createdAt: reports[id].createdAt,
                complete: reports[id].complete,
                data: reports[id] // Store the entire report data
            }));
            setPreviousReports(reportsArray);
        } else {
            setPreviousReports([]);
        }
    };

    const createNewReport = () => {
        setSelectedReportId('new');
        setInitialReportData(null);
        setView('edit');
    };

    const goBack = () => {
        setSelectedReportId(null);
        setInitialReportData(null);
        setView('');
        setReportRunData(null);
    };

    const handleEditReport = () => {
        setView('edit');
    };

    const handleRunReport = (reportRun = null) => {
        setReportRunData(reportRun ? reportRun.data : null);
        setView('run');
    };

    return (
        <div className="container-fluid d-flex flex-column h-100">
            {selectedReportId === null ? (
                <>
                    <div className="row mb-3">
                        <div className="col-12 text-center">
                            <h1>Report Generator</h1>
                            <button className="btn btn-primary" onClick={createNewReport}>Create New Report</button>
                        </div>
                    </div>
                    <div className="row">
                        <div style={{textAlign:"left"}} className="col-12">
                            <h4>Templates</h4>
                            <ul className="list-group">
                                {reportTemplates.map(({ id, name }) => (
                                    <li key={id} className="list-group-item list-group-item-action" onClick={() => loadReportTemplate(id)}>
                                        {name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="row mb-3">
                        <div className="col-12" style={{textAlign:"left"}}>
                            <button className="btn btn-secondary" onClick={goBack}>Back</button>
                        </div>
                    </div>
                    {view === '' && (
                        <>
                        <h2>{initialReportData?.name}</h2>
                            <div className="row mb-3">
                                <div className="col-12 text-center">
                                    <button className="btn btn-primary mr-2" onClick={handleEditReport}>Edit Report</button>
                                    <button className="btn btn-success" onClick={() => handleRunReport(null)}>Run Report</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h4>Previous Reports</h4>
                                    <ul className="list-group">
                                        {previousReports.map(({ id, createdAt, complete, data }) => (
                                            <li key={id} className="list-group-item" onClick={() => handleRunReport(data)}>
                                                {`Report ID: ${id}, Created At: ${new Date(createdAt).toLocaleString()}, Complete: ${complete}`}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                    {view === 'edit' && (
                        <div className="row">
                            <div className="col-12">
                                <ReportLayout
                                    schoolData={schoolData}
                                    reportId={selectedReportId === 'new' ? null : selectedReportId}
                                    initialReportData={initialReportData}
                                />
                            </div>
                        </div>
                    )}
                    {view === 'run' && (
                        <div className="row">
                            <div className="col-12">
                                <RunReportGenerator
                                    schoolData={schoolData}
                                    reportId={selectedReportId === 'new' ? null : selectedReportId}
                                    reportTemplate={initialReportData}
                                    extendedSubmissions={extendedSubmissions}
                                    reportRunData={reportRunData}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ReportGeneratorHome;
